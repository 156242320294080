<template>
    <div class="cs-embed-block cs-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base">
            <div class="container">
                <div class="content-header">
                    <h2 class="cs-title">
                        <ColorSplit :data="data.title" />
                    </h2>
                    <h3 class="cs-sub-title" v-html="data.subTitle"></h3>
                    <span class="cs-text" v-html="data.text"></span>
                </div> 
                <div class="embed-component" v-html="data.content"></div>
            </div>
        </div>
    </div>
</template>
 
<script>
import {computed} from '../libs/common-fn';

export default {
    name: 'Embed',
    components: {
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },

    computed: {
        ...computed('Embed'),
        hasBg() {
            if (this.data.style == 'color') return ' has-background';

            return '';
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';
.cs-embed-block {
    &.cs-style- {
        &color {
            @include cs-block-color;
            .cs-block-base .cs-title,
            .cs-sub-title,
            .embed-component {
                color: $sub_title_color !important;
            }
        }
    
        &light {
            @include cs-block-light;
        }
    
        &dark {
            @include cs-block-dark;
        }
    }

    .embed-component {
        > * {
            max-width: 100%;
        }

        ::v-deep {
            > * {
                max-width: 100%;
            }
        }
    }

    .cs-block-base {
        text-align: center;
        .content-header {
            margin-bottom: $header_margin !important;

            &:last-child {
                margin-bottom: 0px !important
            }

            .cs-title {
                @include title;
                margin-bottom: 20px;
            }

            .cs-subtitle {
                margin: 20px 0px;
            }
        }
    }
}
</style>
